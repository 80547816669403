import styled, { css, keyframes } from 'styled-components';

import { spacing, zIndex } from '@styles/Theme';

const slideUp = keyframes`
	from: {
		opacity: 0
	}

	to: {
		opacity: 1
	}
`;

const setThemeByType = type => {
	if (type === 'success') {
		return css`
			background-color: #4caf50;
		`;
	}

	if (type === 'error') {
		return css`
			background-color: #f44336;
		`;
	}

	if (type === 'warning') {
		return css`
			background-color: #ff9800;
		`;
	}

	if (type === 'info') {
		return css`
			background-color: #2196f3;
		`;
	}

	return css`
		background-color: rgba(0, 0, 0, 0.9);
	`;
};

export const Container = styled.div`
	position: fixed;
	bottom: ${spacing(1)};
	left: 50%;
	z-index: ${zIndex.floated};
	display: flex;
	align-items: center;
	padding: ${spacing(2)};
	color: #fff;
	border-radius: ${spacing(0.5)};
	transform: translateX(-50%);
	opacity: 1;
	animation: ${slideUp} 1s linear;

	${({ type }) => setThemeByType(type)};
`;

export const Button = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: ${spacing(3)};
	height: ${spacing(3)};
	margin-left: ${spacing(1)};
	background-color: transparent;
	border: none;
	border-radius: 100%;
	cursor: pointer;
	transition: background-color 0.2s linear;

	&:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}

	svg {
		width: ${spacing(1)};
		color: white;
		transform: scale(3);
	}
`;
