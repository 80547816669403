import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import CTA from '../index';

function MobileServices({ ...props }) {
	const { allCosmicjsCallToActions } = useStaticQuery(graphql`
		{
			allCosmicjsCallToActions(
				filter: { slug: { eq: "cta-mobile-services" } }
			) {
				nodes {
					metadata {
						description
						hat
						title
					}
				}
			}
		}
	`);

	const data = allCosmicjsCallToActions.nodes[0].metadata;

	return <CTA data={data} {...props} type="inline" noSpacing />;
}

export default MobileServices;
