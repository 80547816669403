import axios from 'axios';
import { AUTH, API_URL, DEV_API_URL } from '@config/constants';

const api = axios.create({
	baseURL: API_URL,
	'Content-Type': 'application/json',
});

export const accessTokenService = async () => {
	try {
		let code = await api.post('oauth/grant-code', {
			client_id: AUTH.client_id,
		});

		code = code.data.redirect_uri.toString();
		/*eslint-disable */

		code = code.split('code=')[1];
		/* eslint-enable */
		return api.post(
			'oauth/access-token',
			{
				code,
				grant_type: AUTH.grant_type,
				// redirect_uri: AUTH.redirect_uri,
			},
			{
				headers: {
					Authorization:
						'Basic ZDU0ZjliYzQtZWJkMS0zMGFiLWFlMmQtMTA4MjhiMTNlNTBjOjAwNDU3MGQ4LTI0M2EtM2Y2Ny04Yzg3LTkxODdhMGMzY2IyMg==',
				},
			}
		);
	} catch (err) {
		return err;
	}
};

export const sendMailService = ({ access_token, formData }) => {
	const { name, email, phone, subject, message, attachments } = formData;

	try {
		const res = api.post(
			`${DEV_API_URL}/email`,
			{
				emailDestinatario: 'infusoes@fleury.com.br',
				assunto: '[Formulário Site Infusões]',
				mensagem: `
					<div style="font-size: 16px">
						<p>Recebemos o preenchimento do formulário de contato em nossa página. Verifique os dados abaixo:</p><br>
						<p><b style="color: #e40b4b">Nome</b>: ${name}</p>
						<p><b style="color: #e40b4b">E-mail</b>: ${email}</p>
						<p><b style="color: #e40b4b">Telefone</b>: ${phone}</p>
						<p><b style="color: #e40b4b">Assunto</b>: ${subject}</p>
						<p><b style="color: #e40b4b">Mensagem</b>: ${message}</p>
					<div>
				`,
				emailType: 'Mail',
				isHtml: true,
				anexos: attachments,
			},
			{
				headers: {
					access_token,
					client_id: AUTH.client_id,
					'Content-Type': 'application/json',
				},
			}
		);

		return res;
	} catch (err) {
		return err;
	}
};

export default api;
