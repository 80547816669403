import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { P } from '@styles/Typography';
import { v4 as uuidv4 } from 'uuid';
import * as Icons from '../Icons';

import * as S from './styles';

const Upload = ({ name, register, setValue, value }) => {
	const input = useRef();

	const [files, setFiles] = useState([]);

	useEffect(() => {
		if (!value) {
			setFiles([]);
		}
	}, [value]);

	const toBase64 = f =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(f);
			reader.onload = () =>
				resolve({ base64: reader.result, type: f.type, fileName: f.name });
			reader.onerror = error => reject(error);
		});

	const onChange = async e => {
		const arrayAttachments = Array.from(e.target.files);
		setFiles(arrayAttachments);

		const arrayToApi = [];
		const base64promises = [];

		arrayAttachments.map(file => base64promises.push(toBase64(file)));

		const resultConvert = await Promise.all(base64promises);

		resultConvert.map(file =>
			arrayToApi.push({
				contentId: uuidv4(),
				content: file.base64.split(',')[1],
				mediaType: file.type,
				contentType: file.type,
				fileName: file.fileName,
				disposition: 'attachment',
			})
		);

		setValue(name, arrayToApi);
	};

	const clearValue = () => {
		input.current.value = '';
		setFiles([]);
		setValue(name, []);
	};

	register({ name, type: 'file' });

	return (
		<S.Upload>
			<S.Container>
				{files && files.length > 0 ? (
					<>
						{files.map((file, index) => (
							<P className="selected" key={`file-upload-${index}`}>
								{file.name} <Icons.FileIcon />
							</P>
						))}
					</>
				) : (
					<P>Anexar imagens ou documentos</P>
				)}
			</S.Container>

			<P>
				Arraste ou clique aqui para anexar imagens de pedidos, solicitações ou
				documentos.
			</P>

			<S.InputUpload
				ref={input}
				type="file"
				multiple
				name={name}
				onChange={onChange}
				accept="image/gif, image/jpeg, image/png, video/mpeg, application/pdf, text/plain "
			/>

			{files && files.length > 0 && (
				<Icons.CloseIcon className="close-icon" onClick={clearValue} />
			)}
		</S.Upload>
	);
};

Upload.propTypes = {
	name: PropTypes.string.isRequired,
	register: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	setValue: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
	value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

Upload.defaultProps = {
	// register: () => {},
	// setValue: () => {},
	value: null,
};

export default Upload;
