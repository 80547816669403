import React from 'react';
import PropTypes from 'prop-types';

import TitleSection from '@components/TitleSection';
import AppointmentButton from '@components/Button/AppointmentButton';

import * as S from './styles';

function Appointments({ data, noSpacing, type, ...rest }) {
	const { hat, title, description } = data;

	return (
		<S.Container {...rest} noSpacing={noSpacing} type={type}>
			<TitleSection hat={hat} title={title} content={description} type={type}>
				<AppointmentButton />
			</TitleSection>
		</S.Container>
	);
}

Appointments.propTypes = {
	data: PropTypes.objectOf(PropTypes.string).isRequired,
	noSpacing: PropTypes.bool,
	type: PropTypes.string,
};

Appointments.defaultProps = {
	noSpacing: false,
	type: 'block',
};

export default Appointments;
