import styled, { css } from 'styled-components';

import { colors, media, spacing, toRem } from '@styles/Theme';
import { H4 } from '@styles/Typography';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const Heading = styled.div``;

export const Content = styled.div`
	margin-top: ${spacing(4)};
`;

const inlineStyle = css`
	@media (${media.desktop}) {
		flex-direction: row;
		justify-content: space-between;
		max-width: 100%;
		text-align: left;

		${Heading} {
			flex: 1;
			max-width: calc(100% / 2);
		}

		${Content} {
			flex: 1;
			max-width: calc(100% / 4);

			a {
				width: 100%;
			}
		}
	}
`;

export const Wrapper = styled(LWrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: ${toRem(880)};
	text-align: center;

	p + p {
		margin-top: 0;
	}

	${({ type }) => type === 'inline' && inlineStyle}
`;

export const Hat = styled(H4)`
	color: ${colors.primary};
	text-transform: lowercase;

	&:first-letter {
		text-transform: uppercase;
	}
`;
