import React, { useCallback, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';

import formatPhone from '@utils/formatPhone';

import { H4 } from '@styles/Typography';

import { Wrapper, Section } from '@components/Layout/styles';

import SEO from '@components/seo';
import Breadcrumb from '@components/Breadcrumb';
import CTA from '@components/CTAS/MobileServices';
import InnerBanner from '@components/InnerBanner';
import Sidebar from '@components/Sidebars/Main';
import TextField from '@components/TextField';
import Button from '@components/Button';
import Notification from '@components/Notification';
import Upload from '@components/Upload';

import { accessTokenService, sendMailService } from '@services/api';

import * as S from './_styles';

const schema = yup.object().shape({
	name: yup.string().min(6, 'Insira seu nome completo'),
	email: yup
		.string()
		.email('E-mail inválido')
		.required('O e-mail é obrigatório'),
	phone: yup
		.string()
		.min(14, 'Insira um telefone válido com DDD')
		.required('O telefone é obrigatório'),
	subject: yup.string().required('O assunto é obrigatório'),
	message: yup.string().required('A mensagem é obrigatória'),
});

const Contato = () => {
	const [showNotification, setShowNotification] = useState(false);
	const [notificationObj, setNotificationObj] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const { meta, pageData } = useStaticQuery(graphql`
		{
			meta: allCosmicjsMeta(filter: { slug: { eq: "meta-contact" } }) {
				nodes {
					metadata {
						title
						description
						cover {
							imgix_url
						}
					}
				}
			}

			pageData: allCosmicjsPages(filter: { slug: { eq: "contact" } }) {
				nodes {
					metadata {
						description
						title
						banner {
							url
							imgix_url
						}
					}
				}
			}
		}
	`);

	const {
		register,
		handleSubmit,
		errors,
		reset,
		watch,
		setValue,
		getValues,
	} = useForm({
		mode: 'onBlur',
		resolver: yupResolver(schema),
		defaultValues: {
			name: '',
			email: '',
			phone: '',
			subject: '',
			message: '',
			attachments: [],
		},
	});

	const name = watch('name');
	const email = watch('email');
	const phone = watch('phone');
	const subject = watch('subject');
	const message = watch('message');
	// const attachments = watch('attachments');

	const {
		metadata: { title: pageTitle, description: pageDescription, banner },
	} = pageData.nodes[0];

	const {
		metadata: { title: metaTitle, description: metaDescription, cover },
	} = meta.nodes[0];

	const onSubmit = useCallback(
		async formData => {
			setIsLoading(true);

			const {
				data: { access_token },
			} = await accessTokenService();

			const resetForm = () => {
				reset();
				setIsLoading(false);

				setShowNotification(true);

				setTimeout(() => {
					setShowNotification(false);
				}, 5000);
			};

			const success = () =>
				setNotificationObj({
					message: 'Mensagem enviada com sucesso!',
					type: 'success',
				});

			await sendMailService({
				access_token,
				formData,
			})
				.then(() => {
					success();
					resetForm();
				})
				.catch(e => {
					if (e.message === 'Network Error') {
						success();
						resetForm();
					} else {
						setNotificationObj({
							message: 'Ops! Houve um erro, tente novamente.',
							type: 'error',
						});
					}
				});
		},
		[reset]
	);

	return (
		<>
			<SEO
				title={metaTitle || 'Contato'}
				description={metaDescription}
				cover={cover.imgix_url}
			/>

			<InnerBanner
				title={pageTitle}
				description={pageDescription}
				bg={banner.imgix_url}
			/>

			<Wrapper>
				<Breadcrumb crumbs={[{ title: 'Contato', slug: 'contato' }]} />
			</Wrapper>

			<Section>
				<S.Wrapper>
					<S.MainContent>
						<H4>Formulário de contato</H4>
						<S.Form onSubmit={handleSubmit(onSubmit)}>
							<S.FormControl>
								<TextField
									error={errors.name}
									model="material"
									name="name"
									placeholder="Nome completo"
									register={register}
									type="text"
									value={name}
								/>
							</S.FormControl>
							<S.DoubleInput>
								<S.FormControl>
									<TextField
										error={errors.email}
										inputMode="email"
										model="material"
										name="email"
										placeholder="E-mail"
										register={register}
										type="email"
										value={email}
									/>
								</S.FormControl>

								<S.FormControl>
									<TextField
										error={errors.phone}
										inputMode="numeric"
										model="material"
										name="phone"
										onChange={e => {
											const {
												target: { value },
											} = e;
											e.target.value = formatPhone(value);
										}}
										placeholder="Telefone"
										register={register}
										type="tel"
										value={phone}
									/>
								</S.FormControl>
							</S.DoubleInput>

							<S.FormControl>
								<TextField
									error={errors.subject}
									model="material"
									name="subject"
									placeholder="Assunto"
									register={register}
									type="text"
									value={subject}
								/>
							</S.FormControl>
							<S.FormControl>
								<Upload
									name="attachments"
									register={register}
									setValue={setValue}
									value={getValues('attachments')}
								/>
							</S.FormControl>

							<S.FormControl>
								<TextField
									error={errors.message}
									fieldType="textarea"
									model="material"
									name="message"
									placeholder="Mensagem"
									register={register}
									type="text"
									value={message}
								/>
							</S.FormControl>

							<S.FormControl>
								<Button type="submit" loading={isLoading}>
									{isLoading ? 'Enviando mensage...' : 'Enviar Mensagem'}
								</Button>
							</S.FormControl>
						</S.Form>
					</S.MainContent>

					<Sidebar />
				</S.Wrapper>
			</Section>

			<Section bgColor="white">
				<Wrapper>
					<CTA />
				</Wrapper>
			</Section>

			{showNotification && (
				<Notification
					message={notificationObj.message}
					type={notificationObj.type}
				/>
			)}
		</>
	);
};

export default Contato;
