import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { CloseIcon } from '@components/Icons';

import * as S from './styles';

const Notification = ({ type, message }) => {
	const [isOpened, setIsOpened] = useState(true);

	return isOpened ? (
		<S.Container type={type}>
			{message}
			<S.Button onClick={() => setIsOpened(false)}>
				<CloseIcon />
			</S.Button>
		</S.Container>
	) : (
		<></>
	);
};

Notification.propTypes = {
	type: PropTypes.string,
	message: PropTypes.string.isRequired,
};

Notification.defaultProps = {
	type: 'generic',
};

export default Notification;
