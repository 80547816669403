import React from 'react';
import PropTypes from 'prop-types';

import { H3, P } from '@styles/Typography';

import * as S from './styles';

const TitleSection = ({ hat, title, content, children, type }) => {
	return (
		<S.Wrapper type={type}>
			<S.Heading>
				{hat && <S.Hat as="h2">{hat}</S.Hat>}
				{title && <H3 as="h1">{title}</H3>}
				{content && <P dangerouslySetInnerHTML={{ __html: content }} />}
			</S.Heading>

			{children && <S.Content>{children}</S.Content>}
		</S.Wrapper>
	);
};

TitleSection.propTypes = {
	children: PropTypes.node,
	content: PropTypes.string,
	hat: PropTypes.string,
	title: PropTypes.string,
	type: PropTypes.string,
};

TitleSection.defaultProps = {
	children: undefined,
	content: undefined,
	hat: undefined,
	title: undefined,
	type: 'block',
};

export default TitleSection;
