import styled from 'styled-components';
import { spacing, toRem } from '@styles/Theme';

export const Upload = styled.div`
	margin-top: ${spacing(4)};
	position: relative;

	p {
		color: #cecece;
		font-size: ${toRem(14)};
		text-align: center;
		margin-top: ${spacing(1)};
	}

	svg {
		width: 20px;

		&.close-icon {
			position: absolute;
			fill: #717171;
			top: 20px;
			right: 20px;
			z-index: 2;

			cursor: pointer;
		}
	}
`;

export const Container = styled.div`
	pointer-events: none;
	padding: ${spacing(2)};
	background-color: #f9f9f9;
	border-radius: ${spacing(2)};
	border: 1px solid #d9d9d9;
	color: #cecece;

	position: relative;

	p {
		margin-top: 0;
		font-size: ${toRem(16)};

		&.selected {
			color: #717171;
		}
	}
`;

export const InputUpload = styled.input`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	opacity: 0;
	cursor: pointer;
	z-index: 1;
`;
