import styled, { css } from 'styled-components';
import { boxShadow, colors, media, spacing, toRem } from '@styles/Theme';

const inlineStyle = css`
	max-width: 100%;
	padding: ${spacing(4)};
	border-radius: ${spacing(2)};
	box-shadow: ${boxShadow};

	@media (${media.tablet}) {
		max-width: 100%;
		padding: ${spacing(4)};
	}

	@media (${media.desktop}) {
		max-width: calc(100% - ${spacing(8)});
		padding: ${spacing(6, 8)};
	}
`;

export const Container = styled.div`
	width: 100%;
	margin: ${spacing(5, 'auto')};
	padding-top: ${spacing(5)};
	border-top: ${toRem(2)} dashed ${colors.lightGrey};

	@media (${media.tablet}) {
		max-width: ${toRem(604)};
		margin-top: ${spacing(7)};
		margin-bottom: ${spacing(7)};
		padding-top: ${spacing(7)};
	}

	@media (${media.desktop}) {
		max-width: ${toRem(665)};
		margin-top: ${spacing(10)};
		margin-bottom: ${spacing(10)};
		padding-top: ${spacing(10)};
	}

	${({ noSpacing }) =>
		noSpacing &&
		css`
			border: none;
			margin: 0 auto;
			padding: 0;

			@media (${media.tablet}) {
				margin: 0 auto;
				padding: 0;
			}

			@media (${media.desktop}) {
				margin: 0 auto;
				padding: 0;
			}
		`}

	${({ type }) => type === 'inline' && inlineStyle}
`;
