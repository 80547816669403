import styled from 'styled-components';

import { boxShadow, media, spacing } from '@styles/Theme';

import { Wrapper as LWrapper } from '@components/Layout/styles';

export const MainContent = styled.div`
	@media (${media.desktop}) {
		flex: 1;
		margin-right: ${spacing(4)};
	}
`;

export const Wrapper = styled(LWrapper)`
	@media (${media.desktop}) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`;

export const Form = styled.form`
	margin: ${spacing(4, 0)};
	padding: ${spacing(4)};
	border-radius: ${spacing(2)};
	box-shadow: ${boxShadow};

	@media (${media.tablet}) {
		padding: ${spacing(8)};
	}
`;

export const FormControl = styled.div`
	button {
		width: 100%;
		margin-top: ${spacing(4)};
	}
`;

export const DoubleInput = styled.div`
	@media (${media.tablet}) {
		display: flex;
		justify-content: space-between;

		${FormControl} {
			width: 100%;

			&:last-child {
				margin-left: ${spacing(4)};
			}
		}
	}
`;
